<template>
  <div class="page-wrapper">
    <b-container-fluid>
      <bo-page-title></bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="2">
              <h5 class="card-title">Inquiry</h5>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <b-form-datepicker id="example-datepicker2" v-model="value2" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name </th>
                <th>Email Address </th>
                <th>Phone Number </th>
                <th>Company Name </th>
                <th>Type of Project </th>
                <th>How'd you find use </th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{user.no}}</td>
                <td>{{user.fullname}}</td>
                <td>{{user.email_address}}</td>
                <td>{{user.phone_number}}</td>
                <td>{{user.company_name}}</td>
                <td>{{user.typeofproject}}</td>
                <td>{{user.find_use}}</td>
                <td><a href="#" class="icon_action"><i class="ti-eye" id="show-btn" @click="$bvModal.show('bv-modal-example')"></i></a></td>
                <b-modal id="bv-modal-example" hide-footer>
                  <template #modal-title>Add What We Do</template>
                  <div class="d-block">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci mollitia magni quas, dolores quibusdam voluptatum aliquam explicabo assumenda expedita ab suscipit dolore vero. Eum eligendi molestias saepe illum assumenda minima.</p>
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn  btn-rounded  btn-default mr-3"
                      @click="$bvModal.hide('bv-modal-example')">Cancel</button>
                    <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
                  </div>
                </b-modal>
              </tr>
            </tbody>
          </table>
        </b-card-body>
        <b-card-footer>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev" next-text="Next"></b-pagination>
        </b-card-footer>
      </b-card>
    </b-container-fluid>
  </div>
</template>
<script>
import GlobalVue from '../libs/Global.vue'
export default {
  extends: GlobalVue,
  data() {
    return {
      rows: 30,
      perPage: 1,
      currentPage: 5,
      value: '',
      value2: '',
      users: [{
          no: 1,
          fullname: 'Tita Aprilianti',
          email_address: 'tita@lingkar9.com',
          phone_number: '+6285890302529',
          company_name: 'PT Lingkar9 Titian Media',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci mollitia magni quas,',
          typeofproject: 'Software Development',
          find_use: 'Google'
        },
        {
          no: 2,
          fullname: 'Galih Kusuma',
          email_address: 'galih@lingkar9.com',
          phone_number: '+6285890302529',
          company_name: 'PT Lingkar Sembilan Titian Media',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci mollitia magni quas,',
          typeofproject: 'Software Development',
          find_use: 'Google'
        },
        {
          no: 3,
          fullname: 'Galih Kusuma',
          email_address: 'galih@lingkar9.com',
          phone_number: '+6285890302529',
          company_name: 'PT Lingkar Sembilan Titian Media',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci mollitia magni quas,',
          typeofproject: 'Software Development',
          find_use: 'Google'
        }
      ]
    }
  }
}
</script>